import React from 'react';
import Context from 'dm-gatsby-context';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

const ViewTransition = ({children, viewId, duration = 500}) => {
  let loading = false;
  let unloading = false;
  const nodeRef = React.useRef(null);
  const getLoading = () => {
    return loading;
  };
  const getUnloading = () => {
    return unloading;
  };
  const enterStarted = () => {
    loading = true;
    unloading = false;
  };
  const enterDone = () => {
    loading = false;
  };
  const exitStarted = () => {
    unloading = true;
  };

  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={viewId}
        nodeRef={nodeRef}
        classNames="transition"
        appear={true}
        enter={true}
        exit={true}
        timeout={duration}
        onEnter={enterStarted}
        onEntered={enterDone}
        onAppeared={enterDone}
        onExit={exitStarted}
        loading={getLoading}
        unloading={getUnloading}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};

export default ViewTransition;
