import React, {useContext, useRef, useEffect} from 'react';
import useState from 'react-usestateref';
import Context from 'dm-gatsby-context';

const KeyboardControl = (props) => {
  const {
    contextData,
    contextFunctions,
    updateContextData,
    updateContextFunctions,
  } = useContext(Context);
  const contextDataRef = useRef(contextData);
  const contextFunctionsRef = useRef(contextFunctions);

  let keyboardControl = false;
  const keyHandler = (evt) => {
    if (evt.key === 'Tab') {
      keyboardControl = true;
    } else if (
      evt.key !== 'Alt' &&
      evt.key !== 'Shift' &&
      evt.key !== 'Enter' &&
      evt.code !== 'Space' &&
      evt.key === 'ArrowUp' &&
      evt.key === 'ArrowDown' &&
      evt.key === 'ArrowLeft' &&
      evt.key === 'ArrowRight'
    ) {
      keyboardControl = false;
    }
    updateContextData({keyboardControl: keyboardControl});
  };
  const clickHandler = (evt) => {
    keyboardControl = false;
    updateContextData({keyboardControl: keyboardControl});
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', keyHandler);
      window.addEventListener('mouseup', clickHandler);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', keyHandler);
        window.removeEventListener('mouseup', clickHandler);
      }
    };
  }, []);
  useEffect(() => {
    contextDataRef.current = contextData;
    contextFunctionsRef.current = contextFunctions;
  }, [contextData, contextFunctions]);

  return (
    <div className={contextData.keyboardControl ? 'keyboard-control' : ''}>
      {props.children}
    </div>
  );
};

export default KeyboardControl;
