import React, {useContext, useRef, useEffect} from 'react';
import Context from 'dm-gatsby-context';
import {
  getConstants,
  getCourseSettings,
  getCourseModules,
  jsonNoUnderscores,
} from '../utils';
import DmButton from 'dm-gatsby-atom-button';
import DmImage from 'dm-gatsby-atom-image';
import {tracking} from '../services/Tracking';
import {GenericData} from 'dm-gatsby-item-renderer/types';

/**
 *
 * @param props - React component props which should include a pageContext key containing the screen's JSON
 *
 * @returns A React component
 */
const Navbar = (props) => {
  const {
    contextData,
    contextFunctions,
    updateContextData,
    updateContextFunctions,
  } = useContext(Context);
  const contextDataRef = useRef(contextData);
  const contextFunctionsRef = useRef(contextFunctions);
  const functions = () => {
    return {
      ...contextFunctionsRef.current,
    };
  };
  useEffect(() => {
    if (contextFunctions.exit === undefined) {
      updateContextFunctions({
        exit: () => {
          updateContextData({
            modal: {
              class: 'exit',
              hideClose: true,
              items: [
                {
                  heading: {
                    level: 2,
                    text: dialogs.exit.headerText,
                  },
                },
                {
                  paragraph: {
                    text: dialogs.exit.bodyText,
                  },
                },
                {
                  button: {
                    text: dialogs.exit.closeButtonText,
                    action: 'closeModal',
                  },
                },
                {
                  button: {
                    text: dialogs.exit.actionButtonText,
                    action: 'confirmExit',
                  },
                },
              ],
              functions: functions(),
            },
          });
          updateContextData({modalOpen: true});
        },
        confirmExit: () => {
          //just close the window
          window.top.close();
        },
        closeModal: () => {
          updateContextData({modal: null});
          updateContextData({modalOpen: false});
        },
        replayAudio: () => {
          updateContextData({
            playbackEnded: false,
            playbackShow: true,
            playbackPlay: true,
            playbackEndAction: () => {
              updateContextData({playbackShow: false});
            },
          });
        },
      });
    }
    contextDataRef.current = contextData;
    contextFunctionsRef.current = contextFunctions;
  }, [contextData, contextFunctions]);

  const dialogs = getConstants().dialogs;
  const uiLabels = getConstants().uiLabels;
  const resources = getConstants().resources;
  const lpSurveyUrl = getConstants().lpSurveyUrl;
  const modules = getCourseModules();
  const settings = getCourseSettings();
  const excludedModules = modules.filter(
    (courseModule) => courseModule.excludeFromMenu === true
  );
  const excludedScreens =
    modules.filter((courseModule) => courseModule.excludeFromMenu === true)?.[0]
      ?.topics?.[0]?.screenIds || [];
  const courseName = settings.courseName;
  const courseSubName = settings.courseSubName;
  const screenJson = jsonNoUnderscores(props.screenJson);
  let currentModule, currentTopic;
  if (screenJson.id && !excludedScreens.includes(screenJson.id)) {
    currentModule = modules.filter((courseModule) =>
      courseModule.topics.some((topic) => {
        return topic.screenIds.includes(screenJson.id);
      })
    )[0];
    currentTopic = currentModule.topics.filter((courseTopic) => {
      return courseTopic.screenIds.includes(screenJson.id);
    })[0];
  }
  const assessment = currentModule?.assessment || currentTopic?.assessment;
  const assessmentResults = tracking.get('assessmentResults') || {};
  const assessmentCorrect: number = assessmentResults
    ? Object.values(assessmentResults as GenericData[]).reduce(
        (acc: number, result) => {
          return result.outcome === 'correct' ? acc + 1 : acc;
        },
        0
      )
    : 0;
  const heading = contextData.menuOpen
    ? courseName
    : currentModule?.menuName || '';
  const subheading = contextData.menuOpen
    ? courseSubName
    : currentTopic?.menuName || '';
  const classes = [
    contextData.modalOpen ? 'modal-open' : null,
    contextData.menuOpen ? 'menu-open' : null,
    contextData.transparentNav ? 'transparent' : null,
    screenJson.showNavbar === false ? 'hidden' : null,
  ]
    .join(' ')
    .trim();
  const skipNav = (evt) => {
    if (evt.key === 'Enter' || evt.code === 'Space') {
      (document.querySelector('div[role="main"]') as HTMLElement).focus();
    }
  };

  if (!excludedScreens.includes(screenJson.id) || contextData.menuOpen) {
    return (
      <header className={classes}>
        <button className="dm-btn skip-navigation" onKeyUp={skipNav}>
          Skip Navigation
        </button>
        {/* <h1>{heading}</h1>
        <h2>{subheading}</h2> */}
        <DmImage className="dm-logo" src="dm-logo.svg" alt="" />
        <DmButton
          className="nav-btn menu-btn"
          label={uiLabels.menu.label}
          action="showMenu"
          functions={functions}
        />
        <nav className="navbar">
          <DmButton
            className="nav-btn"
            label="About"
            goto="./about"
            functions={functions}
          />
          <DmButton
            className="nav-btn"
            label="Portfolio"
            goto="./portfolio"
            functions={functions}
          />
          <DmButton
            className="nav-btn"
            label="Services"
            goto="./services"
            functions={functions}
          />
          <DmButton
            className="nav-btn"
            label="Articles"
            goto="./articles"
            functions={functions}
          />
          <DmButton
            className="nav-btn"
            label="Contact"
            goto="./contact"
            functions={functions}
          />
        </nav>
        <div className="top_pull_quote">
          Your <strong>success</strong> is our success.
        </div>
      </header>
    );
  } else {
    return null;
  }
};

export default Navbar;
