import React, {useContext, useRef, useEffect} from 'react';
import Context from 'dm-gatsby-context';
import useState from 'react-usestateref';
import {getConstants} from '../utils';
import {renderItems, GenericData} from 'dm-gatsby-item-renderer';
import ViewTransition from '../components/ViewTransition';

interface Arguments {
  id?: string;
  sequence?: [];
  leave?: boolean;
  class?: string;
  conditionMet?: string;
  checkConditions?: boolean;
  nextAction?: boolean;
  nextScreen?: boolean;
  nextScene?: boolean;
  showBox?: string;
  playSound?: string;
  playingClass?: string;
  loop?: boolean;
  loopCount?: number;
  loopDelay?: number;
  maxLoops?: number;
  stopSound?: string;
  muteSounds?: boolean;
  unMuteSounds?: boolean;
  playAudio?: GenericData;
  playVideo?: string;
  showChoiceFeedback?: string;
  showFeedback?: GenericData;
  disableChoice?: string;
  saveResult?: string;
  addScreenClass?: string;
  duration?: number;
  removeScreenClass?: string;
  lock?: string;
  unlock?: string;
  selectChoice?: string;
  closeModal?: boolean;
  dismissFeedback?: boolean;
  dismissResults?: boolean;
  activityComplete?: boolean;
  loadScene?: string;
  src?: string;
  readyToContinue?: boolean;
  setOSD?: string;
  setDroppedImage?: string;
  setDroppedButtonText?: string;
  wait?: number;
  startTimer?: boolean;
  stopTimer?: boolean;
  resetTimer?: boolean;
  [key: string]: unknown;
}

const Actions = (props) => {
  const {
    contextData,
    contextFunctions,
    updateContextData,
    updateContextFunctions,
  } = useContext(Context);
  const contextDataRef = useRef(contextData);
  const contextFunctionsRef = useRef(contextFunctions);
  const [conditionsMet, setConditionsMet, conditionsMetRef] = useState([]);
  const [actionSet, setActionSet, actionSetRef] = useState([]);
  const [actionSequence, setActionSequence, actionSequenceRef] = useState([]);
  const [actionSequenceComplete, setActionSequenceComplete] = useState(false);
  const [feedbackSequence, setFeedbackSequence, feedbackSequenceRef] = useState(
    []
  );
  const [screenClasses, setScreenClasses, screenClassesRef] = useState([]);
  const [sounds, setSounds] = useState({});
  const [hovering, setHovering] = useState(null);
  const [answered, setAnswered, answeredRef] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [feedbackActive, setFeedbackActive] = useState(false);
  const [locks, setLocks] = useState({});
  const [results, setResults, resultsRef] = useState([]);
  const [resultsActive, setResultsActive] = useState(false);
  const [activeBox, setActiveBox, activeBoxRef] = useState(null);
  const [playedMedia, setPlayedMedia, playedMediaRef] = useState([]);
  const [activityComplete, setActivityComplete] = useState(false);
  const [screenFunctions, setScreenFunctions] = useState({});
  const screenFunctionsRef = useRef(screenFunctions);
  const [readyToContinue, setReadyToContinue] = useState(false);
  const [osd, setOSD] = useState(null);
  const [delay, setDelay] = useState(0);
  const [timerTime, setTimerTime] = useState('0');
  const dialogs = getConstants().dialogs;
  let currentActionSet = -1;
  let totalResults;
  let soundVolume = 1;
  let timer;
  let currentTimeout;
  let cloneId;
  let randomConnections;
  const actions = {
    setActionSet,
    setConditionMet: (condition) => {
      if (!conditionsMetRef.current.includes(condition)) {
        conditionsMetRef.current.push(condition);
      }
    },
    conditionMet: (condition) => {
      return conditionsMetRef.current.includes(condition);
    },
    numConditionsMet: (args) => {
      return conditionsMetRef.current.length === args.num;
    },
    checkConditions: () => {
      currentActionSet = -1;
      actions.nextAction();
    },
    nextAction: () => {
      if (currentActionSet < actionSetRef.current.length - 1) {
        currentActionSet++;
      } else {
        //currentActionSet = 0;
      }
      let current = actionSetRef.current[currentActionSet];
      let conditionMet = true;
      while (current?.condition) {
        if (current.condition === 'recheckConditions') {
          currentActionSet = 0;
          current = actionSetRef.current[currentActionSet];
        } else {
          let preConditionMet = true;
          if (current.actionArguments.preCondition) {
            let firstChar = current.actionArguments.preCondition.slice(0, 1);
            let condition =
              firstChar === '!'
                ? current.actionArguments.preCondition.slice(1)
                : current.actionArguments.preCondition;
            let preCondition = actions.conditionMet(condition);
            preConditionMet = firstChar === '!' ? !preCondition : preCondition;
          }
          if (preConditionMet) {
            let firstChar = current.condition.slice(0, 1);
            let action =
              firstChar === '!'
                ? current.condition.slice(1)
                : current.condition;
            let condition =
              actions.conditionMet(action) ||
              contextFunctionsRef.current[action]?.(current.actionArguments);
            condition = firstChar === '!' ? !condition : condition;
            if (condition) {
              break;
            } else if (currentActionSet === actionSetRef.current.length - 1) {
              conditionMet = false;
              break;
            } else {
              currentActionSet++;
              current = actionSetRef.current[currentActionSet];
            }
          } else {
            currentActionSet++;
            current = actionSetRef.current[currentActionSet];
          }
        }
      }
      if (current && conditionMet) {
        actions.runActions(current.actionArguments);
      }
    },
    addScreenClass: (args) => {
      if (!screenClassesRef.current.includes(args.class)) {
        screenClassesRef.current.push(args.class);
        setScreenClasses([...screenClassesRef.current]);
      }
      if (args.duration) {
        setTimeout(() => {
          actions.removeScreenClass(args.class);
        }, args.duration * 1000);
      }
    },
    removeScreenClass: (className) => {
      if (screenClassesRef.current.includes(className)) {
        screenClassesRef.current.splice(
          screenClassesRef.current.indexOf(className),
          1
        );
        setScreenClasses([...screenClassesRef.current]);
      }
    },
    getActiveBox: () => {
      return activeBoxRef.current;
    },
    showBox: (args: Arguments) => {
      if (args.leave) {
        setActiveBox(null);
      } else {
        setActiveBox(args.id);
      }
    },
    nextFeedback: () => {
      /*  if (feedbackSequenceRef.current.length > 0) {
    const choiceID = feedbackSequenceRef.current.shift();
    const choice = sceneChoices.filter((choice) => {
      return choice.id === choiceID;
    })[0];
    const fb = choice.correct
      ? choice.correctFeedbackItems
      : choice.incorrectFeedbackItems;
    setFeedback({
      class: 'choice-' + choiceID + '-feedback',
      feedbackItems: fb,
    });
    setFeedbackActive(true);
  } else {
    actions.dismissFeedback();
  } */
    },
    dismissFeedback: () => {
      setFeedbackActive(false);
      setFeedback(null);
      actions.runActions();
    },
    showResults: () => {
      setResultsActive(true);
    },
    countResults: (items: []) => {
      totalResults = items.filter((result: GenericData) => result.id).length;
      return totalResults;
    },
    dismissResults: () => {
      setResultsActive(false);
    },
    noResultsSubmitted: () => {
      return resultsRef.current.length === 0;
    },
    allResultsSubmitted: () => {
      return resultsRef.current.length === totalResults;
    },
    questionAnswered: (args) => {
      return answeredRef.current.includes(args.id);
    },
    numQuestionsAnswered: (args) => {
      return answeredRef.current.length == args.num;
    },
    canContinue: () => {
      return readyToContinue;
    },
    showModal: () => {
      updateContextData({modalOpen: true});
    },
    closeModal: () => {
      updateContextData({modalOpen: false});
    },
    playSound: (args: Arguments) => {
      actions.stopSound(args);
      if (args.leave) {
        return;
      } else {
        sounds[args.id || args.playingClass || args.src] = {
          args: args,
        };
        const soundNum =
          Object.keys(sounds).indexOf(
            args.id || args.playingClass || args.src
          ) + 1;
        let soundFile;
        if (args.maxSounds && soundNum > args.maxSounds && args.maxedOutSound) {
          soundFile = args.maxedOutSound;
        } else {
          soundFile = args.src;
        }
        const sound = new Audio(soundFile);
        sound.id = args.id;
        sound.volume = soundVolume;
        sounds[args.id || args.playingClass || args.src].sound = sound;
        const playPromise = sound.play();
        if (playPromise !== undefined) {
          playPromise.catch(() => {}).then(() => {});
        }
        let loopTimeout;
        sound.addEventListener(
          'ended',
          () => {
            args.loopCount++;
            if (args.loop || args.loopCount < args.maxLoops) {
              if (sounds[args.id || args.playingClass || args.src]) {
                sounds[args.id || args.playingClass || args.src].loopTimeout =
                  setTimeout(() => {
                    actions.playSound(args);
                  }, args.loopDelay * 1000 || 0);
                actions.stopSound(args);
              }
              if (args.loop && args.loopCount === 1) {
                actions.runActions();
              }
            } else {
              actions.stopSound(args);
              actions.runActions();
            }
          },
          false
        );
        if (args.playingClass && soundVolume > 0) {
          let playingClass = args.playingClass;
          if (randomConnections) {
            const randomConnection =
              randomConnections[
                Math.floor(Math.random() * randomConnections.length)
              ];
            playingClass = playingClass.replace(
              'randomizedConnection',
              randomConnection
            );
            if (args.id) {
              screenFunctionsRef.current['setDropConnection']?.(
                args.id,
                randomConnection
              );
            }
          }
          if (args.id) {
            playingClass += ' ' + args.id;
          }
          args.playingClassToRemove = playingClass;
          setTimeout(() => {
            actions.addScreenClass({class: playingClass});
          }, 0);
        }
      }
    },
    stopSound: (args: Arguments = null) => {
      const sound = sounds[args.id || args.playingClass || args.src];
      if (sound) {
        if (sound.args?.playingClassToRemove && soundVolume > 0) {
          actions.removeScreenClass(sound.args.playingClassToRemove);
        }
        if (sound.sound) {
          sound.sound.src = '';
          delete sound.sound;
        }
        if (args.cancelLoop || !sound.loopTimeout) {
          clearTimeout(sound.loopTimeout);
          delete sounds[args.id || args.playingClass || args.src];
        }
      }
    },
    stopSounds: () => {
      Object.entries(sounds as HTMLAudioElement).forEach(([key, sound]) => {
        actions.stopSound({...sound.args, cancelLoop: true});
      });
    },
    muteSounds: () => {
      soundVolume = 0;
      Object.entries(sounds as HTMLAudioElement).forEach(([key, sound]) => {
        if (sound.sound) {
          sound.sound.volume = soundVolume;
        }
      });
    },
    unMuteSounds: () => {
      soundVolume = 1;
      Object.entries(sounds as HTMLAudioElement).forEach(([key, sound]) => {
        if (sound.sound) {
          sound.sound.volume = soundVolume;
        }
      });
    },
    playAudio: (args) => {
      updateContextData({
        playbackSrc: args.src,
        playbackCcFile: args.ccFile,
        playbackAutoPlay: true,
      });
      setTimeout(() => {
        updateContextData({
          playbackPlay: true,
          playbackShow: true,
          playbackEndAction: () => {
            updateContextData({playbackShow: false});
            actions.runActions();
          },
        });
      }, 0);
    },
    playVideo: (args) => {
      updateContextData({
        playbackEndAction: actions.runActions,
      });
      if (typeof args.src === 'string') {
        updateContextData({playbackSrc: args.src});
      }
      updateContextData({playbackPlay: true});
    },
    playbackError: () => {
      updateContextData({
        modal: {
          class: '',
          hideClose: true,
          items: [
            {
              heading: {
                level: 2,
                text: dialogs.mediaError.headerText,
              },
            },
            {
              paragraph: {
                text: dialogs.mediaError.bodyText,
              },
            },
            {
              button: {
                text: dialogs.mediaError.actionButtonText,
                action: 'closeModal',
              },
            },
          ],
          functions: functions(),
        },
      });
      updateContextData({modalOpen: true});
    },
    setMediaPlayed: (args) => {
      if (!actions.mediaHasPlayed(args)) {
        setPlayedMedia([...playedMediaRef.current, args.src]);
      }
    },
    mediaHasPlayed: (args) => {
      return playedMediaRef.current.includes(args.src);
    },
    setTimer: (args: Arguments) => {
      timer = {...args};
      actions.resetTimer();
    },
    resetTimer: () => {
      timer.time = timer.range[0] * 1000;
      const formattedTime = (timer.time / 1000).toFixed(timer.decimals);
      setTimerTime(formattedTime);
    },
    startTimer: () => {
      actions.stopTimer();
      timer.interval = setInterval(() => {
        if (timer.range[0] < timer.range[1]) {
          timer.time += 100;
        } else {
          timer.time -= 100;
        }
        const formattedTime = (timer.time / 1000).toFixed(timer.decimals);
        setTimerTime(formattedTime);
        if (timer.time == 0) {
          actions.stopTimer();
          if (timer.endAction) {
            contextFunctionsRef.current[timer.endAction](
              timer.endActionArguments
            );
          }
        }
      }, 100);
    },
    stopTimer: () => {
      clearInterval(timer.interval);
      timer.interval = null;
    },
    setCloneId: (id) => {
      cloneId = id;
    },
    setRandomConnections: (connections) => {
      randomConnections = connections;
    },
    setActionSequence: (sequence) => {
      setActionSequenceComplete(false);
      setActionSequence(sequence);
    },
    runActions: (args: Arguments = null) => {
      if (args?.sequence) {
        actions.setActionSequence([...args.sequence]);
        args = {...args};
        delete args.sequence;
      }
      if (actionSequenceRef.current.length > 0) {
        args = actionSequenceRef.current.shift();
      }
      if (!args) {
        setTimeout(() => {
          setActionSequenceComplete(true);
          cloneId = null;
        }, 0);
        return;
      }

      let ranFunctions = false;
      if (args.wait) {
        setDelay(args.wait * 1000);
        clearTimeout(currentTimeout);
        currentTimeout = setTimeout(() => {
          actions.runActions();
        }, args.wait * 1000);
      }
      if (args.checkConditions) {
        actions.checkConditions();
      }
      if (args.nextAction) {
        actions.nextAction();
      }
      if (args.showBox) {
        actions.showBox({id: args.showBox});
        ranFunctions = true;
      }
      if (args.stopSounds) {
        actions.stopSounds();
        ranFunctions = true;
      }
      if (args.stopSound) {
        actions.stopSound({
          id: args.stopSound === 'cloneId' ? cloneId : args.stopSound,
          cancelLoop: true,
        });
        ranFunctions = true;
      }
      if (args.playSound) {
        actions.playSound({
          id: args.id === 'cloneId' ? cloneId : args.id,
          src: args.playSound,
          loopCount: 0,
          loop: args.loop,
          loopDelay: args.loopDelay,
          maxLoops: args.maxLoops,
          maxSounds: args.maxSounds,
          maxedOutSound: args.maxedOutSound,
          playingClass: args.playingClass,
        });
      }
      if (args.playAudio) {
        actions.playAudio({
          src:
            args.playAudio.streamingSrc || args.playAudio.src || args.playAudio,
          ccFile: args.playAudio.ccFile,
        });
      }
      if (args.playVideo) {
        actions.playVideo({src: args.playVideo});
      }
      if (args.showChoiceFeedback) {
        /* if (args.showChoiceFeedback === 'selected') {
          setFeedbackSequence([...selectedChoices]);
        } else {
          setFeedbackSequence([
            Object.entries(sceneChoices as Choice).map(([key, choice]) => {
              return choice.id;
            }),
          ]);
        }
        feedbackSequenceRef.current.sort();
        actions.nextFeedback(); */
      }
      if (args.showFeedback) {
        const condition = args.showFeedback.condition
          ? contextFunctionsRef.current[args.showFeedback.condition as string]()
          : true;
        if (args.showFeedback.feedbackItems) {
          setFeedback(args.showFeedback);
          setFeedbackActive(true);
        } else if (condition && args.showFeedback.correctFeedbackItems) {
          setFeedback({
            correct: true,
            class: args.showFeedback.class,
            feedbackItems: args.showFeedback.correctFeedbackItems,
          });
          setFeedbackActive(true);
        } else if (!condition && args.showFeedback.incorrectFeedbackItems) {
          setFeedback({
            incorrect: true,
            class: args.showFeedback.class,
            feedbackItems: args.showFeedback.incorrectFeedbackItems,
          });
          setFeedbackActive(true);
        }
      }
      if (args.saveResult) {
        let newResults = [...resultsRef.current];
        if (!newResults.includes(args.saveResult)) {
          newResults.push(args.saveResult);
        }
        setResults(newResults);
        ranFunctions = true;
      }
      if (args.addScreenClass) {
        actions.addScreenClass({
          class: args.addScreenClass,
          duration: args.duration,
        });
        ranFunctions = true;
      }
      if (args.removeScreenClass) {
        actions.removeScreenClass(args.removeScreenClass);
        ranFunctions = true;
      }
      if (args.lock) {
        setLocks({...locks, [args.lock]: true});
        ranFunctions = true;
      }
      if (args.unlock) {
        setLocks({...locks, [args.unlock]: false});
        ranFunctions = true;
      }
      if (args.activityComplete) {
        setActivityComplete(true);
        ranFunctions = true;
      }
      if (args.readyToContinue) {
        setReadyToContinue(args.readyToContinue);
        ranFunctions = true;
      }
      if (args.setOSD) {
        setOSD({text: args.setOSD});
        ranFunctions = true;
      }
      if (args.nextScreen) {
        contextFunctionsRef.current.nextScreen();
      }
      if (args.nextScene) {
        screenFunctionsRef.current['nextScene']();
      }
      if (args.loadScene) {
        screenFunctionsRef.current['loadScene']({id: args.loadScene});
      }
      // Catch any other arguments and run their functions on the context or screen (if they exist)
      const specialCaseActions = [
        'event',
        'leave',
        'loop',
        'sequence',
        'wait',
        'checkConditions',
        'nextAction',
        'showBox',
        'stopSounds',
        'stopSound',
        'playSound',
        'playAudio',
        'playVideo',
        'showChoiceFeedback',
        'showFeedback',
        'saveResult',
        'addScreenClass',
        'removeScreenClass',
        'lock',
        'unlock',
        'activityComplete',
        'readyToContinue',
        'setOSD',
        'nextScreen',
        'nextScene',
        'loadScene',
      ];
      Object.keys(args).forEach((actionName) => {
        if (
          !specialCaseActions.includes(actionName) &&
          args[actionName] !== null
        ) {
          if (actions[actionName]) {
            actions[actionName](args[actionName]);
            ranFunctions = true;
          } else if (screenFunctionsRef.current[actionName]) {
            screenFunctionsRef.current[actionName](args[actionName]);
            ranFunctions = true;
          } else if (contextFunctionsRef.current[actionName]) {
            contextFunctionsRef.current[actionName](args[actionName]);
            ranFunctions = true;
          }
        }
      });
      if (ranFunctions) {
        actions.runActions();
      }
    },
    setActivityComplete: (complete: boolean) => {
      if (complete === undefined) {
        complete = true;
      }
      setActivityComplete(complete);
      contextFunctionsRef.current.setScreenComplete();
    },
    setScreenFunctions,
    setHovering,
    setLocks: (lockIds: string[]) => {
      let newLocks = {};
      Object.entries(lockIds).map(([key, id]) => {
        newLocks[id] = true;
      });
      setLocks({...locks, ...newLocks});
    },
    resetActions: (resetFor = null) => {
      setActionSet([]);
      setActivityComplete(false);
      setScreenClasses([]);
      setSounds({});
      setActiveBox(null);
      setActionSequence([]);
      setAnswered([]);
      setLocks({});
      setFeedback(null);
      setFeedbackActive(false);
      setOSD(null);
      if (resetFor === 'screen') {
        soundVolume = 1;
        randomConnections = null;
        setConditionsMet([]);
        setResults([]);
        setScreenFunctions({});
        actions.stopSounds();
      }
    },
  };
  const functions = () => {
    return {
      ...contextFunctionsRef.current,
      ...actions,
      ...screenFunctionsRef.current,
    };
  };

  useEffect(() => {
    updateContextData({
      screenClasses,
      results,
      resultsActive,
      activeBox,
      answered,
      feedbackActive,
      actionSequenceComplete,
      activityComplete,
      readyToContinue,
      osd,
      screenFunctions,
      hovering,
      timerTime,
      locks,
    });
    updateContextFunctions(actions);
  }, []);
  useEffect(() => {
    updateContextData({
      screenClasses,
      results,
      resultsActive,
      activeBox,
      answered,
      feedbackActive,
      actionSequenceComplete,
      activityComplete,
      readyToContinue,
      osd,
      screenFunctions,
      hovering,
      timerTime,
      locks,
    });
  }, [
    screenClasses,
    results,
    resultsActive,
    activeBox,
    answered,
    feedbackActive,
    actionSequenceComplete,
    activityComplete,
    readyToContinue,
    osd,
    screenFunctions,
    hovering,
    timerTime,
    locks,
  ]);
  useEffect(() => {
    contextFunctionsRef.current = contextFunctions;
  }, [contextFunctions]);
  useEffect(() => {
    screenFunctionsRef.current = screenFunctions;
  }, [screenFunctions]);
  return (
    <ViewTransition viewId={feedback ? feedback.class : 'fb'}>
      <>
        {feedback && (
          <div
            className={[
              'feedback dialog',
              feedback.class,
              feedback.correct ? 'correct' : null,
              feedback.incorrect ? 'incorrect' : null,
            ]
              .join(' ')
              .trim()}
          >
            <div className="box">
              {renderItems(feedback.feedbackItems, {
                props: props,
                functions: functions,
              })}
            </div>
          </div>
        )}
      </>
    </ViewTransition>
  );
};

export default Actions;
