import React, {useEffect} from 'react';
import {ContextProviderComponent} from 'dm-gatsby-context';
import {useStaticQuery, graphql} from 'gatsby';
import Actions from '../services/Actions';
import Navigation from '../services/Navigation';
import KeyboardControl from '../services/KeyboardControl';
import Navbar from './Navbar';
import Menu from './Menu';
import Footer from './Footer';
import ViewTransition from '../components/ViewTransition';
import DmModal from 'dm-gatsby-molecule-modal';
import {library} from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/app.scss';
import {debounce} from '../utils';

library.add(far);
library.add(fas);

function isTouchDevice() {
  if (typeof window === 'undefined') {
    return;
  }
  //return true; //ONLY FOR TESTING... REMOVE BEFORE PUBLISHING
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}
function isPhone() {
  if (typeof window === 'undefined') {
    return;
  }
  return (
    isTouchDevice() &&
    (matchMedia('(max-width: 430px)').matches ||
      matchMedia('(max-height: 430px)').matches)
  );
}

let prevContentSizePixels = {
  width: 960,
  height: 540,
};
let updateScaleLimit = 100;
let updateScaleCount = 0;

function updateScale() {
  if (typeof window === 'undefined') {
    return;
  }

  AOS.refresh();
  /* const viewportSizePixels = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const isSquareOrWider = viewportSizePixels.width > viewportSizePixels.height;
  let contentSizePixels;
  if (isPhone() && isSquareOrWider) {
    contentSizePixels = {
      width: 780,
      height: 360,
    };
  } else if (isPhone()) {
    contentSizePixels = {
      width: viewportSizePixels.width,
      height: viewportSizePixels.height,
    };
  } else {
    contentSizePixels = {
      width: 960,
      height: 540,
    };
  }
  const widthRatio = viewportSizePixels.width / contentSizePixels.width;
  const heightRatio = viewportSizePixels.height / contentSizePixels.height;
  const constrainingDimension = widthRatio < heightRatio ? 'width' : 'height';
  const newScale =
    viewportSizePixels[constrainingDimension] /
    contentSizePixels[constrainingDimension];
  const root = document.documentElement;
  root.style.setProperty(
    '--content-width',
    contentSizePixels.width.toString() + 'px'
  );
  root.style.setProperty(
    '--content-height',
    contentSizePixels.height.toString() + 'px'
  );
  root.style.setProperty('--fit-window-scale', newScale.toString());
  //minimize the address bar
  const scaleWasUpdated =
    prevContentSizePixels.width !== contentSizePixels.width ||
    updateScaleCount < updateScaleLimit;
  if (isPhone() && scaleWasUpdated) {
    prevContentSizePixels = contentSizePixels;
    setTimeout(function () {
      window.scrollTo(0, 100);
      updateScaleCount++;
      updateScale();
    }, 0);
  } else {
    updateScaleCount = 0;
  } */
}
updateScale();
const debouncedUpdateScale = debounce(updateScale, 250);

function parallax(event) {
  const position = 2;
  let x = (event.view.innerWidth - event.x * position) / 100;
  let y = (event.view.innerHeight - event.y * position) / 100;
  x *= 1.5;
  y *= 1.5;
  document.querySelectorAll('.parallax .bg-img img').forEach((element) => {
    element.style.transform = `translate(${x}px, ${y}px)`;
  });
}

function scrollPos(event) {
  const pageScroll = document.querySelector('html').scrollTop;
  document.querySelectorAll('.blade.sticky').forEach((blade) => {
    const bladePos =
      pageScroll -
      document.querySelector('.screen').offsetTop -
      blade.offsetTop;
    const bladeH = blade.querySelector('.sticky-content').scrollHeight;
    const bladeProgress = (bladePos / bladeH) * 100;
    blade.style.setProperty('--progress-width', bladeProgress + '%');
  });
}

const Layout = ({children, location}) => {
  const images = useStaticQuery(graphql`
    query {
      files: allFile(filter: {dir: {regex: "/.*?src/images.*?/"}}) {
        edges {
          node {
            extension
            publicURL
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            svg {
              originalContent
            }
          }
        }
      }
    }
  `);
  images.files.edges = [...images.files.edges];

  useEffect(() => {
    if (typeof window !== 'undefined') {
      AOS.init();
      document.addEventListener('aos:in', ({detail}) => {
        if (
          detail.dataset.aos === 'video-autoplay' &&
          detail.firstChild.tagName === 'VIDEO'
        ) {
          if (!detail.firstChild.paused) {
            detail.firstChild.currentTime = 0;
          }
          detail.firstChild.play();
        }
      });
      document.addEventListener('aos:out', ({detail}) => {
        //console.log("animated out", detail);
        if (
          detail.dataset.aos === 'video-autoplay' &&
          detail.firstChild.tagName === 'VIDEO'
        ) {
          detail.firstChild.pause();
        }
      });
      updateScale();
      window.addEventListener('resize', debouncedUpdateScale);

      window.addEventListener('scroll', scrollPos);

      document.addEventListener('mousemove', parallax);
    }
    return () => {
      if (typeof window !== 'undefined') {
        //console.log('destroy');
        window.removeEventListener('resize', debouncedUpdateScale);
        document.removeEventListener('mousemove', parallax);
      }
    };
  }, []);

  return (
    <ContextProviderComponent initialContext={{images}}>
      <KeyboardControl>
        <Navigation />
        <Navbar screenJson={children.props.pageContext} />
        <Menu screenJson={children.props.pageContext} />
        {/* <Help role="complementary" />
<Resources role="complementary" /> */}
        <ViewTransition
          viewId={children.props.pageContext._id}
          duration={children.props.pageContext._transitionDuration}
        >
          {children}
        </ViewTransition>
        <Footer />
        <Actions />
        <DmModal />
      </KeyboardControl>
    </ContextProviderComponent>
  );
};

export default Layout;
