import React, {useContext, useRef, useEffect} from 'react';
import useState from 'react-usestateref';
import Context from 'dm-gatsby-context';
import {navigate} from 'gatsby';
import {
  getConstants,
  getCourseSettings,
  getCourseModules,
  jsonNoUnderscores,
} from '../utils';
import {tracking} from '../services/Tracking';

/**
 *
 * @param props - React component props which should include a pageContext key containing the screen's JSON
 *
 * @returns A React component
 */
const Navigation = (props) => {
  const {
    contextData,
    contextFunctions,
    updateContextData,
    updateContextFunctions,
  } = useContext(Context);
  const contextDataRef = useRef(contextData);
  const contextFunctionsRef = useRef(contextFunctions);

  const constants = getConstants();
  const settings = getCourseSettings();
  const modules = getCourseModules();
  const [started, setStarted] = useState([]);
  const [completed, setCompleted, completedRef] = useState([]);
  const [locked, setLocked] = useState([]);
  const [percentComplete, setPercentComplete, percentCompleteRef] = useState(0);
  const getCurrent = () => {
    const currentScreen = contextDataRef.current.currentScreenJson
      ? contextDataRef.current.currentScreenJson
      : jsonNoUnderscores(props.screenJson);
    const currentModule = modules.filter((courseModule) =>
      courseModule.topics.some((topic) =>
        topic.screenIds.includes(currentScreen.id)
      )
    )[0];
    const currentTopic = currentModule.topics.filter((topic) =>
      topic.screenIds.includes(currentScreen.id)
    )[0];
    const beginningOfTopic =
      currentTopic.screenIds.indexOf(currentScreen.id) == 0;
    const beginningOfModule =
      beginningOfTopic && currentModule.topics.indexOf(currentTopic) == 0;
    const beginningOfCourse =
      beginningOfTopic &&
      beginningOfModule &&
      modules.indexOf(currentModule) == 0;
    const endOfTopic =
      currentTopic.screenIds.indexOf(currentScreen.id) ==
      currentTopic.screenIds.length - 1;
    const endOfModule =
      endOfTopic &&
      currentModule.topics.indexOf(currentTopic) ==
        currentModule.topics.length - 1;
    const endOfCourse =
      endOfModule &&
      endOfTopic &&
      modules.indexOf(currentModule) == modules.length - 1;
    return {
      screen: currentScreen,
      topic: currentTopic,
      module: currentModule,
      beginningOfTopic: beginningOfTopic,
      beginningOfModule: beginningOfModule,
      beginningOfCourse: beginningOfCourse,
      endOfTopic: endOfTopic,
      endOfModule: endOfModule,
      endOfCourse: endOfCourse,
    };
  };
  const customFunctions = {
    prevScreen: () => {
      updateContextData({playbackPause: true});
      updateContextData({modal: null});
      contextFunctionsRef.current.resetActions('screen');
      const current = getCurrent();
      if (settings.showMenuBetweenModules && current.beginningOfModule) {
        contextFunctionsRef.current.showMenu();
      } else if (settings.showMenuBetweenTopics && current.beginningOfTopic) {
        contextFunctionsRef.current.showMenu();
      } else if (!current.beginningOfCourse) {
        let prevModule = modules[modules.indexOf(current.module) - 1];
        let prevTopic;
        let prevScreen;
        if (current.beginningOfModule) {
          prevTopic = prevModule.topics[prevModule.topics.length - 1];
          prevScreen = prevTopic.screenIds[prevTopic.screenIds.length - 1];
        } else if (current.beginningOfTopic) {
          prevTopic =
            current.module.topics[
              current.module.topics.indexOf(current.topic) - 1
            ];
          prevScreen = prevTopic.screenIds[prevTopic.screenIds.length - 1];
        } else {
          prevScreen =
            current.topic.screenIds[
              current.topic.screenIds.indexOf(current.screen.id) - 1
            ];
        }
        navigate('/' + prevScreen);
      }
    },
    nextScreen: () => {
      updateContextData({
        playbackPause: true,
        modal: null,
      });
      contextFunctionsRef.current.resetActions('screen');
      const current = getCurrent();
      if (settings.showMenuBetweenModules && current.endOfModule) {
        contextFunctionsRef.current.showMenu();
      } else if (settings.showMenuBetweenTopics && current.endOfTopic) {
        contextFunctionsRef.current.showMenu();
      } else if (!current.endOfCourse) {
        let nextScreen;
        if (current.endOfModule) {
          nextScreen =
            modules[modules.indexOf(current.module) + 1].topics[0].screenIds[0];
        } else if (current.endOfTopic) {
          nextScreen =
            current.module.topics[
              current.module.topics.indexOf(current.topic) + 1
            ].screenIds[0];
        } else {
          nextScreen =
            current.topic.screenIds[
              current.topic.screenIds.indexOf(current.screen.id) + 1
            ];
        }
        navigate('/' + nextScreen);
      }
    },
    setScreenComplete: (id) => {
      if (!id) {
        const current = getCurrent();
        id = current.screen.id;
      }
      if (!completedRef.current.includes(id)) {
        completedRef.current.push(id);
      }
      let totalScreens = 0;
      let totalScreensComplete = 0;
      modules.map((module) => {
        let topicsComplete = 0;
        module.topics.map((topic) => {
          let screensComplete = 0;
          topic.screenIds.map((screenId) => {
            if (screenId !== 'browser-compatibility') {
              totalScreens++;
              if (
                !topic.completionRequired ||
                completedRef.current.includes(screenId)
              ) {
                screensComplete++;
                totalScreensComplete++;
              }
            }
          });
          if (screensComplete === topic.screenIds.length) {
            topicsComplete++;
            if (!completedRef.current.includes(topic.id)) {
              completedRef.current.push(topic.id);
            }
          }
        });
        if (topicsComplete === module.topics.length) {
          if (!completedRef.current.includes(module.id)) {
            completedRef.current.push(module.id);
          }
        }
      });
      setCompleted(completedRef.current.sort());
      tracking.set('completed', completedRef.current);
      setPercentComplete(totalScreensComplete / totalScreens);
      tracking.set('percentComplete', percentCompleteRef.current * 100);
      if (percentCompleteRef.current === 1) {
        tracking.setLMSComplete('passed', 100);
      }
    },
  };

  useEffect(() => {
    if (contextFunctions.prevScreen === undefined) {
      updateContextFunctions({
        ...customFunctions,
      });
    }
    contextDataRef.current = contextData;
    contextFunctionsRef.current = contextFunctions;
  }, [contextData, contextFunctions]);
  useEffect(() => {
    if (contextData.currentScreenJson) {
      const currentScreenJson = contextData.currentScreenJson as {
        id?: string;
        bookmarkScreen?: boolean;
        autoComplete?: boolean;
        sceneIds?: string[];
        audio: any;
      };
      if (currentScreenJson?.bookmarkScreen !== false) {
        tracking.set('bookmark', currentScreenJson?.id);
      }
      const current = getCurrent();
      if (!started.includes(current.module.id)) {
        started.push(current.module.id);
      }
      if (!started.includes(current.topic.id)) {
        started.push(current.topic.id);
      }
      if (!started.includes(currentScreenJson?.id)) {
        started.push(currentScreenJson?.id);
      }
      tracking.set('started', started);
      if (currentScreenJson?.autoComplete !== false) {
        customFunctions.setScreenComplete(currentScreenJson?.id);
      }
      setTimeout(() => {
        if (
          currentScreenJson?.audio?.autoPlay === true &&
          (!currentScreenJson?.audio?.autoPlayOnce ||
            !(contextData.audioPlayed as string[])?.includes(
              currentScreenJson?.audio?.src
            ))
        ) {
          updateContextData({
            playbackEnded: false,
            playbackShow: true,
            playbackPlay: true,
          });
        }
      }, 0);
      window.dispatchEvent(new Event('resize'));
      //trigger page change event for DM tracker
      var event = new Event('dm-tracker-content-change');
      if (currentScreenJson?.sceneIds) {
        event['sceneId'] = currentScreenJson?.sceneIds[0];
      }
      window.top.document.dispatchEvent(event);
    }
  }, [contextData.currentScreenJson]);
  useEffect(() => {
    tracking.start(constants);
    if (tracking.get('started')) {
      setStarted(tracking.get('started'));
    }
    if (tracking.get('completed')) {
      setCompleted(tracking.get('completed'));
    }
    if (tracking.get('locked')) {
      setLocked(tracking.get('locked'));
    }
  }, []);
  useEffect(() => {
    if (!contextData.playbackEnded) {
      return;
    }
    const currentScreenJson = contextData.currentScreenJson as {
      sceneIds?: string[];
      autoAdvance?: boolean;
    };
    const isScene = currentScreenJson.sceneIds?.length;
    if (!contextData.menuOpen && !isScene && currentScreenJson.autoAdvance) {
      updateContextData({playbackShow: false});
      customFunctions.nextScreen();
    }
  }, [contextData.playbackEnded]);

  return null;
};

export default Navigation;
