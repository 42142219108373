import React, {useContext, useRef, useEffect} from 'react';
import Context from 'dm-gatsby-context';
import DmIcon from 'dm-gatsby-atom-icon';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

library.add(faFacebook, faInstagram, faLinkedin, faTwitter);

/**
 *
 * @param props - React component props which should include a pageContext key containing the screen's JSON
 *
 * @returns A React component
 */
const Footer = (props) => {
  const {
    contextData,
    contextFunctions,
    updateContextData,
    updateContextFunctions,
  } = useContext(Context);
  const contextDataRef = useRef(contextData);
  const contextFunctionsRef = useRef(contextFunctions);
  const functions = () => {
    return {
      ...contextFunctionsRef.current,
    };
  };

  const currentYear = new Date().getFullYear();
  const classes = [''].join(' ').trim();

  return (
    <footer>
      <div className="social-media-footer">
        <div className="footer-email">
          <a href="mailto:info@designmedia.com">info@designmedia.com</a>
        </div>
        <div className="footer-copyright">
          <p>© {currentYear} Design Media, Inc.</p>
          <p>Design Media is a trademark of Design Media, Inc.</p>
        </div>
        <div className="footer-icons">
          <a href="http://www.facebook.com/DesignMediaSF" target="_blank">
            <DmIcon style="fab" icon="facebook" />
          </a>
          <a href="https://www.instagram.com/designmediasf/" target="_blank">
            <DmIcon style="fab" icon="instagram" />
          </a>
          <a
            href="https://www.linkedin.com/company/design-media-inc-"
            target="_blank"
          >
            <DmIcon style="fab" icon="linkedin" />
          </a>
          <a href="https://twitter.com/DesignMediaSF" target="_blank">
            <DmIcon style="fab" icon="twitter" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
