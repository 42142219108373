import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

//custom tags for the html head
export function HtmlHead({location, params, data, pageContext}) {
  const settings = getCourseSettings();
  let title = settings.courseName;
  if (pageContext.menuName !== '') {
    title += ' | ' + pageContext.menuName;
  }
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={settings.courseDescription} />
    </>
  );
}

export function jsonNoUnderscores(json: Record<string, unknown>) {
  var jsonStr = JSON.stringify(json);
  jsonStr = jsonStr.replace(/"_/g, '"');
  let cleanJson = JSON.parse(jsonStr);

  return cleanJson;
}

export function debounce(func: () => unknown, delay: number) {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
}

export function shuffleArray(arr) {
  return arr
    .map((a) => ({sort: Math.random(), value: a}))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value);
}

function runMetadataQueries() {
  const query = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          settings {
            courseName
            courseSubName
            courseDescription
          }
          modules {
            _id
            moduleName
            menuName
            topics {
              _id
              topicName
              menuName
              screenIds
            }
          }
          constants {
            uiLabels {
              menu {
                label
                search
              }
              playback {
                cc
                fastForward
                mute
                pause
                play
                rewind
                scrub
                volume
              }
              back
              backIcon
              close
              forward
              forwardIcon
              help
            }
            languages {
              _code
              name
              prompt
              actionButtonText
            }
            dialogs {
              unsavedEdits {
                closeButtonText
                actionButtonText
                cancelButtonText
                headerText
                bodyText
                _icon
              }
              selectLanguage {
                ShowSelect
                closeButtonText
                actionButtonText
                cancelButtonText
                headerText
                bodyText
                _icon
              }
              helpGeneral {
                closeButtonText
                actionButtonText
                cancelButtonText
                headerText
                bodyText
                _icon
              }
              mediaError {
                closeButtonText
                actionButtonText
                cancelButtonText
                headerText
                bodyText
                _icon
              }
            }
            _playbackControlsIcons {
              _play
              _pause
              _rewind
              _fastForward
              _volume
              _volumeMute
              _cc
            }
            _easingTypes {
              linear
              easeInSine
              easeOutSine
              easeInOutSine
              easeInQuad
              easeOutQuad
              easeInOutQuad
              easeInCubic
              easeOutCubic
              easeInOutCubic
              easeInQuart
              easeOutQuart
              easeInOutQuart
              easeInQuint
              easeOutQuint
              easeInOutQuint
              easeInExpo
              easeOutExpo
              easeInOutExpo
              easeInCirc
              easeOutCirc
              easeInOutCirc
              easeInBack
              easeOutBack
              easeInOutBack
            }
            _animation {
              zoomOutForward {
                easing
                scaleY
                opacity
                scaleX
              }
              zoomOut {
                easing
                opacity
                scaleX
                scaleY
              }
              zoomInBounce {
                keyframeStyle
              }
              slideOutUp {
                easing
              }
              zoomIn {
                easing
                opacity
                scaleX
                scaleY
              }
              slideOutRight {
                easing
                opacity
                x
              }
              slideOutLeft {
                easing
                opacity
                x
              }
              slideOutDown {
                easing
                opacity
                y
              }
              slideInRight {
                easing
                opacity
                x
              }
              slideInUp {
                easing
                opacity
                y
              }
              slideInLeft {
                easing
                opacity
                x
              }
              slideInDownBounce {
                duration
                keyframeStyle
              }
              slideInDown {
                easing
                opacity
                y
              }
              removeShown {
                removeClass
              }
              removeClass {
                removeClass
              }
              flipOutForward {
                easing
                opacity
                rotateX
              }
              flipOutBackward {
                easing
                opacity
                rotateX
              }
              flipInForward {
                easing
                opacity
                rotateX
              }
              flipInBackward {
                easing
                opacity
                rotateX
              }
              fadeOut {
                easing
                opacity
              }
              fadeIn {
                easing
                opacity
              }
              defaults {
                duration
              }
              addShown {
                addClass
              }
              addClass {
                addClass
              }
            }
            _build
            _component
            _firebaseUrl
            _project
            _reviewUrl
            _scorm
            companyName
            copyrightYear
          }
        }
      }
    }
  `);
  return query;
}

export function getConstants() {
  const query = runMetadataQueries();
  return jsonNoUnderscores(query.site.siteMetadata.constants);
}

export function getCourseSettings() {
  const query = runMetadataQueries();
  const settings = jsonNoUnderscores(query.site.siteMetadata.settings);
  delete settings.module;
  return settings;
}

export function getCourseModules() {
  const query = runMetadataQueries();
  return jsonNoUnderscores(query.site.siteMetadata.modules);
}
