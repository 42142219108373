import React, {useContext, useState, useRef, useEffect, Fragment} from 'react';
import Context from 'dm-gatsby-context';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {Link} from 'gatsby';
import {getCourseModules, getCourseSettings} from '../utils';
import {tracking} from '../services/Tracking';
import {GenericData} from 'dm-gatsby-item-renderer';
import DmImage from 'dm-gatsby-atom-image';

/**
 *
 * @param props - React component props which should include a pageContext key containing the screen's JSON
 *
 * @returns A React component
 */
const Menu = (props) => {
  const {
    contextData,
    contextFunctions,
    updateContextData,
    updateContextFunctions,
  } = useContext(Context);
  const contextDataRef = useRef(contextData);
  const contextFunctionsRef = useRef(contextFunctions);
  const functions = () => {
    return {
      ...contextFunctionsRef.current,
    };
  };
  const modules = getCourseModules();
  const settings = getCourseSettings();
  const modulesCompleted = Object.values(modules).reduce(
    (accumulator: number, module: GenericData) => {
      return tracking.get('completed')?.includes(module.id)
        ? accumulator + 1
        : accumulator;
    },
    0
  ) as number;
  const lockedByPrerequisites = (module) => {
    let locked = false;
    if (module.prerequisites) {
      Object.entries(module.prerequisites).map(([key, id]) => {
        if (!tracking.get('completed').includes(id)) {
          locked = true;
        }
      });
    }
    return locked;
  };
  const menuItemClicked = () => {
    contextFunctionsRef.current.resetActions('screen');
    contextFunctionsRef.current.hideMenu();
  };
  const keyHandler = (evt) => {
    if (
      contextDataRef.current.keyboardControl &&
      menuElement.current &&
      evt.key === 'Tab'
    ) {
      if (
        (document.activeElement ===
          menuElement.current.querySelector('a:first-of-type') &&
          evt.shiftKey) ||
        (document.activeElement ===
          menuElement.current.querySelector('a:last-of-type') &&
          !evt.shiftKey)
      ) {
        evt.preventDefault();
        contextFunctionsRef.current.hideMenu();
      }
    }
  };
  let menuElement = useRef(null);
  let screenPlaybackShow;
  let screenPlaybackSrc;
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', keyHandler);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', keyHandler);
      }
    };
  }, []);
  useEffect(() => {
    contextDataRef.current = contextData;
    contextFunctionsRef.current = contextFunctions;
    if (contextFunctions.showMenu === undefined) {
      updateContextData({menuOpen: false});
      updateContextFunctions({
        showMenu: () => {
          if (contextDataRef.current.menuOpen) {
            contextFunctionsRef.current.hideMenu();
          } else {
            contextFunctionsRef.current.muteSounds();
            const currentScreen = contextDataRef.current.currentScreenJson as {
              id: string;
            };
            const currentModule = modules.filter((courseModule) =>
              courseModule.topics.some((topic) =>
                topic.screenIds.includes(currentScreen.id)
              )
            )[0];
            setTopicMenu(currentModule.id);
            screenPlaybackSrc = contextDataRef.current.playbackCurrentSrc;
            screenPlaybackShow = contextDataRef.current.playbackShow;
            updateContextData({
              modal: null,
              modalOpen: false,
              menuOpen: true,
              playbackPause: true,
            });
            if (settings.menuAudio && !tracking.get('menu-audio-played')) {
              setTimeout(() => {
                updateContextData({
                  playbackEnded: false,
                  playbackShow: true,
                  playbackPlay: true,
                  playbackEndAction: () => {
                    updateContextData({playbackShow: false});
                  },
                });
              }, 0);
              tracking.set('menu-audio-played', true);
            } else {
              updateContextData({playbackShow: false});
            }
            setTimeout(() => {
              if (contextDataRef.current.keyboardControl) {
                menuElement.current.querySelector('a:first-of-type').focus();
              }
            }, 100);
          }
        },
        hideMenu: () => {
          contextFunctionsRef.current.unMuteSounds();
          updateContextData({
            menuOpen: false,
            playbackSrc: screenPlaybackSrc,
            playbackShow: screenPlaybackShow,
          });
          if (contextDataRef.current.keyboardControl) {
            (
              document.querySelector('.navbar .menu-btn') as HTMLElement
            ).focus();
          }
        },
      });
    }
  }, [contextData, contextFunctions]);
  const [topicMenu, setTopicMenu] = useState('');
  return (
    <TransitionGroup component={null}>
      {contextData.menuOpen && (
        <CSSTransition
          appear={true}
          enter={true}
          exit={true}
          in={true}
          classNames="transition"
          timeout={500}
        >
          <div ref={menuElement} className="menu" role="navigation">
            {modules.map((courseModule) => {
              const moduleLocked = lockedByPrerequisites(courseModule);
              if (!courseModule.excludeFromMenu) {
                const classes = [
                  'menu-item',
                  courseModule.id.toLowerCase(),
                  moduleLocked ? 'locked' : '',
                  tracking.get('completed').includes(courseModule.id)
                    ? 'completed'
                    : '',
                  courseModule.id === topicMenu ? 'current' : '',
                  !tracking.get('started').includes(module.id) &&
                  !tracking.get('completed').includes(module.id)
                    ? 'not-started'
                    : '',
                  tracking.get('started').includes(module.id) &&
                  !tracking.get('completed').includes(module.id)
                    ? 'started'
                    : '',
                  tracking.get('completed').includes(module.id)
                    ? 'completed'
                    : '',
                ]
                  .join(' ')
                  .trim();
                return (
                  <Link
                    key={courseModule.id}
                    to={'/' + courseModule.topics[0].screenIds[0]}
                    className={classes}
                    onClick={menuItemClicked}
                  >
                    <h3>{courseModule.menuName}</h3>
                  </Link>
                );
              }
            })}
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default Menu;
