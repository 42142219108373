import React, {useContext, useRef, useEffect} from 'react';
import Context from 'dm-gatsby-context';
import {GenericData, Choice} from 'dm-gatsby-item-renderer';
//import lmsAdapter from 'gulp-dm-scorm/comm';

interface Tracking {
  storageAdapter: SCORM;
  cacheId: string;
  constants: GenericData;
  cache: GenericData;
  lmsData: GenericData;
  start: Function;
  loadCache: Function;
  reset: Function;
  get: Function;
  set: Function;
  save: Function;
  remove: Function;
  empty: Function;
  getLMSVariable: Function;
  setLMSVariable: Function;
  getLMSData: Function;
  setLMSData: Function;
  saveLMSAnswers: Function;
  setLMSComplete: Function;
}

interface SCORM {
  SCOGetValue: Function;
  SCOSetValue: Function;
  SCOsetQuestions: Function;
  SCOCommit: Function;
  SCOmarkCourseComplete: Function;
}

export const tracking: Tracking = {
  storageAdapter: null,
  cacheId: '',
  constants: {},
  cache: {},
  lmsData: {},
  start: (constants) => {
    tracking.constants = constants;
    tracking.cacheId =
      constants.project + '-' + constants.component + '-' + constants.build;
    tracking.loadCache();
    tracking.storageAdapter = null; /* lmsAdapter({
      gui: tracking,
      version: tracking.constants.scorm,
      //debug: true,
    }); */
    tracking.getLMSData();
    if (typeof tracking.lmsData.lmsready !== 'undefined') {
      if (
        tracking.getLMSVariable('lms_lesson_status') == 'browse' ||
        tracking.getLMSVariable('lms_lesson_status') == 'review' ||
        tracking.getLMSVariable('lms_lesson_status') == 'completed'
      ) {
        //settings.navigationUnlocked = true;
        //settings.assessmentCompleted = true;
      }
    }
  },
  loadCache: () => {
    if (localStorage && localStorage[tracking.cacheId]) {
      tracking.cache = JSON.parse(localStorage[tracking.cacheId]);
    }
    return tracking.cache;
  },
  reset: (skipStatus) => {
    tracking.cache = {};
    tracking.cache.locale = null;
    tracking.cache.bookmark = null;
    tracking.cache.started = [];
    tracking.cache.completed = [];
    tracking.cache.locked = [];
    tracking.cache.assessmentCompletionDate = '';
    tracking.cache.assessmentTries = 0;
    tracking.cache.assessmentResults = {};
    tracking.lmsData.lms_score = 0;
    if (!skipStatus) {
      tracking.lmsData.lms_lesson_mode = 'normal';
      tracking.lmsData.lms_lesson_status = 'incomplete';
      tracking.lmsData.lms_success_status = 'unknown';
    }
    tracking.save();
  },
  get: (key = null) => {
    return key ? tracking.cache[key] : tracking.cache;
  },
  set: (key, val) => {
    tracking.cache[key] = val;
    tracking.save();
  },
  save: () => {
    if (localStorage) {
      localStorage[tracking.cacheId] = JSON.stringify(tracking.cache);
    }
    tracking.setLMSData();
  },
  remove: (key) => {
    delete tracking.cache[key];
    tracking.save();
  },
  empty: () => {
    for (let key in tracking.cache) {
      tracking.remove(key);
    }
  },
  //LMS FUNCTIONS
  getLMSVariable: function (prop) {
    return tracking.lmsData[prop];
  },
  setLMSVariable: function (prop, val) {
    tracking.lmsData[prop] = val;
  },
  getLMSData: function () {
    if (typeof tracking.lmsData.lmsready === 'undefined') {
      return;
    }

    if (tracking.lmsData.lms_suspend_data) {
      const suspendData = JSON.parse(
        tracking.lmsData.lms_suspend_data as string
      );
      tracking.cache = {};

      tracking.cache.locale = suspendData.locale || null;

      tracking.cache.bookmark = suspendData.bookmark;
      tracking.cache.started = suspendData.started;
      tracking.cache.completed = suspendData.completed;
      tracking.cache.locked = suspendData.locked;
      tracking.cache.assessmentCompletionDate =
        suspendData.assessmentCompletionDate;
      tracking.cache.assessmentTries = parseInt(suspendData.assessmentTries);

      if (suspendData.assessmentResults) {
        tracking.cache.assessmentResults = suspendData.assessmentResults;
      }

      tracking.lmsData.lms_lesson_mode = suspendData.lms_lesson_mode;
      tracking.lmsData.lms_lesson_status = suspendData.lms_lesson_status;
      tracking.lmsData.lms_success_status = suspendData.lms_success_status;
    } else if (
      tracking.lmsData.lms_lesson_mode != 'browse' &&
      tracking.lmsData.lms_lesson_mode != 'review'
    ) {
      tracking.reset(true);
    }
  },
  setLMSData: function (lms_save_now = true) {
    if (typeof tracking.lmsData.lmsready === 'undefined' || !lms_save_now) {
      return;
    }

    let suspendData: GenericData = {};
    suspendData.locale = tracking.cache.locale;
    if (tracking.cache.bookmark) {
      suspendData.bookmark = tracking.cache.bookmark;
    }
    suspendData.started = tracking.cache.started;
    suspendData.completed = tracking.cache.completed;
    suspendData.locked = tracking.cache.locked;
    suspendData.assessmentCompletionDate =
      tracking.cache.assessmentCompletionDate;
    suspendData.assessmentTries = tracking.cache.assessmentTries;
    if (tracking.cache.assessmentResults) {
      suspendData.assessmentResults = tracking.cache.assessmentResults;
    }
    suspendData.lms_lesson_mode = tracking.lmsData.lms_lesson_mode;
    suspendData.lms_lesson_status = tracking.lmsData.lms_lesson_status;
    suspendData.lms_success_status = tracking.lmsData.lms_success_status;

    const scorm = tracking.constants.scorm;
    if (tracking.cache.bookmark) {
      tracking.storageAdapter.SCOSetValue(
        scorm && scorm == '2004' ? 'cmi.location' : 'cmi.core.lesson_location',
        tracking.cache.bookmark
      );
    }
    tracking.storageAdapter.SCOSetValue(
      'cmi.suspend_data',
      JSON.stringify(suspendData)
    );
    tracking.storageAdapter.SCOCommit();
    tracking.lmsData.lms_suspend_data =
      tracking.storageAdapter.SCOGetValue('cmi.suspend_data');
  },
  saveLMSAnswers: function (activity) {
    if (typeof tracking.lmsData.lmsready === 'undefined') {
      return;
    }

    const letters = [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
    ];
    let userAnswers = [];
    let correctAnswers = [];
    let score;
    //Store lms data for screen.
    if (activity.questionType == 'choice') {
      Object.entries(activity.choices as Choice).map(([key, choice]) => {
        if (activity.answers.includes(choice.id)) {
          userAnswers.push(letters[key]);
        }
        if (choice.correct) {
          correctAnswers.push(letters[key]);
          if (choice.score !== undefined) {
            score = choice.score;
          }
        }
      });
    } else if (activity.questionType == 'matching') {
      Object.entries(activity.drops as any[]).map(([key, drop]) => {
        let answer = letters[key];
        let correct = letters[key];
        const matches = activity.matches[drop.id];
        if (matches) {
          const allMatches = {
            ...matches.correctDrags,
            ...matches.incorrectDrags,
          };
          Object.entries(allMatches).map(([key, match]) => {
            answer +=
              '.' + (activity.drags.findIndex((drag) => match === drag.id) + 1);
          });
        } else {
          answer += '.0';
        }
        if (drop.matches?.length > 0) {
          Object.entries(drop.matches).map(([key, match]) => {
            correct +=
              '.' + (activity.drags.findIndex((drag) => match === drag.id) + 1);
          });
        } else {
          correct += '.0';
        }
        userAnswers.push(answer);
        correctAnswers.push(correct);
      });
    } else if (activity.questionType == 'numeric') {
      userAnswers = [Number(activity.answers)]; //won't save correctly unless the answer is an actual number
    }
    userAnswers.sort();
    correctAnswers.sort();
    let assessmentNum = 1; //will need to update if there are ever more than 1 assessment
    let questionId = activity.assessment
      ? 'Assessment-' + assessmentNum + '-' + activity.id
      : 'KnowledgeCheck-' + activity.id;
    let questionWeight = score !== undefined ? score : 1;
    let lmsAnswers = [
      'intChildren',
      'indexNumb',
      questionId,
      activity.questionType,
      questionWeight,
      userAnswers,
      activity.outcome,
      correctAnswers,
    ];
    tracking.storageAdapter.SCOsetQuestions(...lmsAnswers);
    //console.log('assessment answers:', lmsAnswers);
  },
  setLMSComplete: function (status, score) {
    if (
      typeof tracking.lmsData.lmsready === 'undefined' ||
      tracking.lmsData.lms_lesson_status === 'completed'
    ) {
      return;
    }

    let today = new Date();
    tracking.cache.assessmentCompletionDate =
      today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
    tracking.lmsData.lms_score = score;
    let scorm = tracking.constants.scorm;
    if (scorm && scorm == '2004') {
      tracking.lmsData.lms_lesson_status = 'completed';
      tracking.lmsData.lms_success_status = status;
    } else {
      tracking.lmsData.lms_lesson_status =
        status == 'passed' ? 'completed' : status;
    }
    tracking.storageAdapter.SCOmarkCourseComplete();
  },
};
